<template>
  <store-transaction-details />
</template>

<script>
import StoreTransactionDetails from '@/components/pages/sales/StoreTransactionDetails'

export default {
  components: {
    StoreTransactionDetails
  }
}
</script>
